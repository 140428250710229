import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const showAuth = (state, flag) => {
	state.showAuth = flag
}
export const showAuthModel = (state, flag) => {
	state.showAuthModel = flag
}

export const showForgetPassword = (state, flag) => {
	state.showForgetPassword = flag
}

export const showAgreementContentModel = (state, flag) => {
	state.showAgreementContentModel = flag
}

export const showChangePassword = (state, flag) => {
	state.showChangePassword = flag
}

export const setUser = (state, payload) => {
    localStorage.setItem("email", payload.email);
    localStorage.setItem("password", payload.password);
    localStorage.setItem("keepMeLoggedIn", payload.keepMeLoggedIn);
    localStorage.setItem("ls_data", JSON.stringify(payload.data));
    localStorage.setItem("sso_data", JSON.stringify(payload.sso_data));
    localStorage.setItem("profilePicUrl", payload.profilePicUrl);
    localStorage.setItem("localforage/smartartclient/authtoken", payload.authtoken);
}

export const setAgreeContentModelValue = (state, flag) => {
    state.setAgreeContentModelValue = flag;
}