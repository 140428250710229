import localforage from 'localforage'

export const getSiteSettingsClient = ({ commit, dispatch }, payload) => {
    let query = payload && payload.edit ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getSiteSettingsClient' + query).then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getSiteSettingsClient " + error, { root: true });
        return false;
    });
}

export const getCountryCode = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/phone-code').then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getCountryCode " + error, { root: true });
        return false;
    });
}

export const getIndustryTypeList = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-list').then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getIndustryTypeList " + error, { root: true });
    });
}

export const getIndustrySubTypeList = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/master-industry-sub-list/' + payload.id).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getIndustrySubTypeList " + error, { root: true });
    });
}

export const getCitiesFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/get-city/' + searchQuery).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getCitiesFromBackOffice " + error, { root: true });
    });
}

export const getCountryStateByCity = ({ commit, dispatch }, { payload, context }) => {
    let id = payload.id;
    return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/get-country-state-by-city/' + id).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getCountryStateByCity " + error, { root: true });
    });
}

export const getAgreementContentData = ({ commit, dispatch }, { payload, context }) => {
    let page_key = payload.page_key;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-agreements-record/' + page_key).then((response) => {
        return response.data.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getAgreementContentData " + error, { root: true });
    });
}

export const getCategoryFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-master-data-categories/' + searchQuery).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getCategoryFromBackOffice " + error, { root: true });
    });
}

export const getSubCategoryFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-master-data-sub-categories/' + searchQuery).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getSubCategoryFromBackOffice " + error, { root: true });
    });
}


export const getFrameVendorFromBackOffice = ({ commit, dispatch }, { payload, context }) => {
    let searchQuery = payload.searchQuery;
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-frame-vendor/' + searchQuery).then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getFrameVendorFromBackOffice " + error, { root: true });
    });
}

export const getMasterDataFromBackOffice = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getMasterData').then((response) => {
        return response.data;
    }).catch ((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getUnitsFromBackOffice " + error, { root: true });
    });
}
export const sendBellNotification = ({ commit, dispatch }, { payload, context }) => {
    try {
        return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/bell/notify/records',payload).then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in sendBellNotification response action", error);
    }
}
export const getBellNotification = ({ commit, dispatch }) => {
    try {
        return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/bell/notify/records').then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in getBellNotification response action", error);
    }
}
export const markSeenBellNotification = ({ commit, dispatch }, { payload, context }) => {
    try {
        return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/bell/notify/mark-seen',payload).then((response) => {
            return response.data;
        })
    } catch (error) {
        console.log("Error in markSeenBellNotification response action", error);
    }
}
export default {    
    getSiteSettingsClient,
    getCountryCode,
    getIndustryTypeList,
    getIndustrySubTypeList,
    getCitiesFromBackOffice,
    getCountryStateByCity,
    getAgreementContentData,
    getCategoryFromBackOffice,
    getSubCategoryFromBackOffice,
    getFrameVendorFromBackOffice,
    getMasterDataFromBackOffice,
    sendBellNotification,
    getBellNotification,
    markSeenBellNotification
}