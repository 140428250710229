export default [
	{
        path: '/seller-profile',
        component: () => import('../components/ProfileParent.vue'),
        children: [
            {
                path: '/seller-profile',
                component: () => import('../components/partials/MyProfile.vue'),
                name: 'seller-profile',
                meta: {},
            },
            {
                path: '/edit-profile',
                component: () => import('../components/partials/EditProfile.vue'),
                name: 'edit-profile',
                meta: {},
            },
        ]
    },
    

]