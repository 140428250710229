export const getShowAuth = (state) => {
	return state.showAuth
}
export const getShowAuthModel = (state) => {
	return state.showAuthModel
}
export const getShowForgetPassword = (state) => {
	return state.showForgetPassword
}

export const getContentsAgreementModel = (state) => {
	return state.showAgreementContentModel
}

export const getShowChangePassword = (state) => {
	return state.showChangePassword
}
export const getContentsAgreementModelValue = (state) => {
	return state.setAgreeContentModelValue
}